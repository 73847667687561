import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import Loader from '../Loader';
import '../../index.css';

import { get, post, put, deleteM } from "../../utility/Adapter";
import { createError, removeErrors } from "../../utility/Validation";
import dimage from '../../images/document.jpg';
import Cookies from 'js-cookie';
import axios from 'axios';

const Documents = () => {

    const [loading, setLoading] = useState(false);
    const [myProfile, setMyProfile] = useState([]);
    const [file, setFile] = useState('');
    const [progress, setProgess] = useState(0);
    const [dStatus, setDStatus] = useState(0);
    const [proposalAmount, setProposalAmount] = useState(0);
    const [documentId, setDocumentId] = useState(0);
    const [documentFileName, setDocumentFileName] = useState(null);

    const [documentsNoLicenseNurse, setDocumentsNoLicenseNurse] = useState([
        { document_name: 'passport', id: 'F1', order: 1 },
        { document_name: 'birth certificate', id: 'F7', order: 2 },
        { document_name: 'marriage certificate (if applicable)', id: 'F8', order: 3 },
        { document_name: 'higher secondary (+2) Certificate', id: 'F2', order: 4 },
        { document_name: 'Mark list', id: 'F26', order: 5 },
        { document_name: 'transcript', id: 'F4', order: 6 },
        { document_name: 'degree certificate', id: 'F3', order: 7 },
        { document_name: 'state nursing council registration', id: 'F5', order: 8 },
        { document_name: 'experience certificates (not applicable freshers)', id: 'F6', order: 9 },
        { document_name: 'Application form for your Nursing recognition in Germany ', id: 'F9', order: 10 },
        { document_name: 'Power of attorney for handling your documents ', id: 'F10', order: 11 },
        { document_name: 'vaccination document', id: 'F11', order: 12 }
    ]);
    const [documentsLicenseNurse, setDocumentsLicenseNurse] = useState([
        { document_name: 'passport', id: 'F12', order: 1 },
        { document_name: 'urkunde - nursing registration certificate in Germany', id: 'F13', order: 2 },
        { document_name: 'bescheid - deficit notice certificate in Germany', id: 'F14', order: 3 },
        { document_name: 'B2 Certificate', id: 'F28', order: 4 },
        { document_name: 'Experience certificate', id: 'F29', order: 5 },
        { document_name: 'Visa copy', id: 'F30', order: 6 },
        { document_name: '3 Month salary slip', id: 'F15', order: 7 }
    ]);
    const [documentsStudents, setDocumentsStudents] = useState([
        { document_name: 'passport', id: 'F16', order: 1 },
        { document_name: 'birth certificate', id: 'F17', order: 2 },
        { document_name: 'secondary (10th) Certificate & Mark list', id: 'F18', order: 3 },
        { document_name: 'higher secondary (+2) Certificate & Mark list', id: 'F19', order: 4 },
        { document_name: 'degree Certificate (if applicable)', id: 'F27', order: 5 },
        { document_name: 'marriage certificate (if applicable)', id: 'F24', order: 6 },
        { document_name: 'motivation letter (Word Format)', id: 'F21', order: 7 },
        { document_name: 'power of attorney ', id: 'F22', order: 8 },
        { document_name: 'ANTRAG AUF ANERKENNUNG/BEWERTUNG VON BILDUNGSNACHWEISEN (+2 registration application form) ', id: 'F23', order: 9 },
        { document_name: 'vaccination document', id: 'F25', order: 10 }
    ]);
    const [uploadDocuments, setUploadDocuments] = useState([]);
    const [documentsCount, setDocumentsCount] = useState(0);
    const [candidatesDocumentsCount, setCandidatesDocumentsCount] = useState(0);
    const [step, setStep] = useState("1");

    useEffect(() => {
        _loadProfile();
        _loadDocuments();

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);


        //window.$('[data-toggle="tooltip"]').tooltip();
    }, []);

    useEffect(() => {
        if (step == "4") {
            window.location.href = "/documents/verify";
        }
    }, [step])

    const _loadProfile = () => {
        get('candidates/profile').then(async function (response) {
            setMyProfile(response.data.data);
            setStep(response.data.data.candidate_step);

            if (response.data.data.candidate_degree == 'STUDENT') {
                setUploadDocuments(documentsStudents);

                if (response.data.data.candidate_family_status != 'm') {
                    setCandidatesDocumentsCount(documentsStudents.length - 1);
                }
                else {
                    setCandidatesDocumentsCount(documentsStudents.length);
                }
            }
            else if (response.data.data.candidate_licence == 'N' || response.data.data.candidate_licence == '' || response.data.data.candidate_licence == undefined) {
                setUploadDocuments(documentsNoLicenseNurse);

                if (response.data.data.candidate_family_status != 'm') {
                    setCandidatesDocumentsCount(documentsNoLicenseNurse.length - 1);
                }
                else {
                    setCandidatesDocumentsCount(documentsNoLicenseNurse.length);
                }
            }
            else if (response.data.data.candidate_licence == 'Y') {
                setUploadDocuments(documentsLicenseNurse);

                if (response.data.data.candidate_family_status != 'm') {
                    setCandidatesDocumentsCount(documentsLicenseNurse.length - 1);
                }
                else {
                    setCandidatesDocumentsCount(documentsLicenseNurse.length);
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadDocuments = () => {
        setLoading(true);
        post('candidates/documents').then(async function (response) {
            setTimeout(function () {

                if (response.data.data.length > 0) {
                    setDocumentId(response.data.data[0].document_id);
                }


                for (var j = 0; j < response.data.data.length; j++) {
                    var id = response.data.data[j].id;
                    var doc_status = response.data.data[j].document_status;
                    var document_file_name = response.data.data[j].document_file_name;

                    if (doc_status == '1' || doc_status == 1) {
                        var rw = document.getElementById('reject-warn');
                        if (rw != null) {
                            rw.style.setProperty("display", "block", "important");
                        }
                        var ele = document.getElementsByClassName('r' + id);
                        for (var k = 0; k < ele.length; k++) {
                            ele[k].style.setProperty("display", "block", "important");
                            ele[k].setAttribute('title', response.data.data[j].document_action_reason);
                        }
                    }

                    var element = document.getElementsByClassName(id);
                    for (var i = 0; i < element.length; i++) {
                        if (doc_status == undefined || doc_status == '0') {
                            element[i].style.setProperty("display", "none", "important")
                        }
                    }

                    element = document.getElementsByClassName(id + '_v');
                    for (var i = 0; i < element.length; i++) {
                        if (doc_status == undefined || doc_status == '0') {
                            element[i].style.setProperty("display", "block", "important");
                            element[i].setAttribute('data-fileName', document_file_name);
                        }
                        //element[i].setAttribute("href", process.env.REACT_APP_BASE_URL + 'view/' + response.data.data[j].document_file_name);
                    }

                    window.$('document').ready(function () {
                        window.$('[data-toggle="tooltip"]').tooltip();
                    })
                }

                setLoading(false);
            }, 1000);


            setDocumentsCount(response.data.data.length);
            setDStatus(parseInt(response.data.document_status));

            if (response.data.document_amount != undefined) {
                setProposalAmount(response.data.document_amount);
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    //STUDENT
    //NONE

    const getData = () => {
        try {
            const value = Cookies.get('hcUser')
            if (value !== undefined) {
                return value;
            }
        } catch (e) {
            // error reading value
        }
    }

    const uploadFile = (e, data, id, order) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
        setProgess(0);

        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('name', data);
        formData.append('id', id);
        formData.append('order', order);

        const token = getData();

        const headers = {
            "Contetnt-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`,
            'HC-User-Token': `Bearer ${token}`,
        }

        var element = document.getElementsByClassName(id);
        for (var i = 0; i < element.length; i++) {
            element[i].style.display = 'none';
        }

        axios.post(process.env.REACT_APP_BASE_URL + 'candidates/document-upload', formData, {
            headers: headers
        })
            .then((res) => {
                element = document.getElementsByClassName(id + '_v');
                for (var i = 0; i < element.length; i++) {
                    element[i].style.setProperty("display", "block", "important");
                    //element[i].setAttribute("href", process.env.REACT_APP_BASE_URL + 'view/' + res.data.name);
                }

                var ele = document.getElementsByClassName('r' + id);
                for (var k = 0; k < ele.length; k++) {
                    ele[k].style.setProperty("display", "none", "important");
                }

                _loadDocuments();
            })
            .catch((error) => {
                alert('Document upload failed, Please try agian')
            })

        /*var element = document.getElementsByClassName(id);
        for (var i = 0; i < element.length; i++) {
            element[i].style.display = 'none';
        }

        console.log("formData", formData)

        post('candidates/document-upload', formData)
            .then(res => {
                element = document.getElementsByClassName(id + '_v');
                for (var i = 0; i < element.length; i++) {
                    element[i].style.display = 'block';
                    //element[i].setAttribute("href", process.env.REACT_APP_BASE_URL + 'view/' + res.data.name);
                }

                var ele = document.getElementsByClassName('r' + id);
                for (var k = 0; k < ele.length; k++) {
                    ele[k].style.setProperty("display", "none", "important");
                }

                _loadDocuments();
            }).catch(err => console.log(err));
        /*axios.post(process.env.REACT_APP_BASE_URL + 'candidates/document-upload', formData, {
            onUploadProgress: (ProgressEvent) => {
                let progress = Math.round(
                ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                setProgess(progress);
            }
        }).then(res => {
            
        }).catch(err => console.log(err));*/
    }

    const payNow = () => {
        var amount = proposalAmount * 100; //Razorpay consider the amount in paisevar options = {
        var options = {
            "key": process.env.REACT_APP_razorpaytest_id,
            "amount": amount, // 2000 paise = INR 20, amount in paisa
            "name": "",
            "description": "",
            'order_id': "",
            "handler": function (response) {
                var values = {
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: amount,
                }
                post('payments/upgrade', values)
                    .then(res => {
                        setDStatus(4);
                        setStep("3");
                        alert("Success");
                    })
                    .catch(e => console.log(e))
            },
            "prefill": {
                "name": myProfile.candidate_first_name,
                "email": myProfile.candidate_email,
                "contact": myProfile.candidate_phone,
            },
            "notes": {
                "address": ""
            },
            "theme": {
                "color": "#528ff0"
            }
        };

        post('payments/order', { amount: amount })
            .then(res => {
                options.order_id = res.data.id;
                options.amount = res.data.amount;
                console.log(options)
                var rzp1 = new window.Razorpay(options);
                rzp1.open();
            })
            .catch(err => console.log(err));
    }

    var documentStatus = {
        0: 'Pending',
        1: 'Submitted',
        2: 'Waiting Proposal',
        3: 'Waiting Payment',
        4: 'Translation Progress',
        5: 'Translation Under Review',
        6: 'Completed'
    }

    const download = () => {
        window.open(
            process.env.REACT_APP_BASE_URL + 'documents/translated/download/' + documentId,
            '_blank'
        );
    }

    const _view = (d) => {
        var element = document.getElementsByClassName(d.id + '_v');

        if (element != null) {
            setDocumentFileName(element[0].getAttribute('data-fileName'));
            window.$("#largeLeftModalExample").modal("toggle");
        }
    }


    return (
        <>
            <Header />
            <Loader loading={loading} />

            {/* Modal Start*/}
            <div className="modal modal-right large fade" id="largeLeftModalExample" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabelLeftLarge">Document Preview</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <embed src={process.env.REACT_APP_BASE_URL + 'documents/preview/' + documentFileName} type="application/pdf" width={'100%'} height={'700px'} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal End*/}

            <main>
                <div className="container-fluid">
                    <div className="row">
                        {/* Menu Start */}
                        <SideBar step={step} license={myProfile.candidate_licence} />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div className="col">
                            {/* Title and Top Buttons Start */}
                            <div className="page-title-container mb-3">
                                <div className="row">
                                    <div className="col mb-2">
                                        <h1 className="mb-2 pb-0 display-4" id="title">Documents [Upload <b>PDF</b> format Only]</h1>
                                        <div className="text-muted font-heading text-small">Document Details..</div>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            <div className="row">
                                <div className="col-12">

                                    <div className="row">
                                        {
                                            dStatus == '3' &&
                                            <div className="col-12 mt-1 mb-6">
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className="alert alert-success" role="alert">
                                                            <h4 className="alert-heading">Your document translation payment is due - {proposalAmount}!</h4>
                                                            <p>
                                                            </p>
                                                            <hr />
                                                            <p className="mb-0">
                                                                Please contact our support team to make the payment.<br />
                                                                Phone: +49 1517 123 2244, Email: info@hireandcare.de
                                                                {/*<button type="button" className="btn btn-primary mb-1" onClick={() => payNow()}>Pay Now</button>*/}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            dStatus == '6' && myProfile.candidate_licence != 'Y' &&
                                            <div className='row'>
                                                <div className="col-12 mt-1 mb-6">
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div className="alert alert-info" role="alert">
                                                                <h4 className="alert-heading">Successfully translated your document!</h4>
                                                                <p>
                                                                </p>
                                                                <hr />
                                                                <p className="mb-0">
                                                                    <button type="button" className="btn btn-primary mb-1" onClick={() => download()}>Download Now</button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            dStatus == '6' && myProfile.candidate_licence == 'Y' &&
                                            <div className='row'>
                                                <div className="col-12 mt-1 mb-6">
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div className="alert alert-info" role="alert">
                                                                <h4 className="alert-heading">Successfully approved your documents!</h4>
                                                                <p>
                                                                    Our executives will contact you..
                                                                </p>

                                                                <p className="mb-0">
                                                                    Please contact our support team if any queries.<br />
                                                                    Phone: +49 1517 123 2244, Email: info@hireandcare.de
                                                                    {/*<button type="button" className="btn btn-primary mb-1" onClick={() => payNow()}>Pay Now</button>*/}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>
                            </div>

                            <div className='row mb-2 mt-5 mb-5'>
                                <div className="col-12">
                                    {
                                        myProfile.candidate_licence != 'Y' && dStatus > 0 &&
                                        <div className="col-12 hh-grayBox rem-pad">
                                            <div className="row justify-content-between">
                                                {
                                                    dStatus >= 1 &&
                                                    <div className="order-tracking completed">
                                                        <span className="is-complete"></span>
                                                        <p>Apply</p>
                                                    </div>
                                                }

                                                {
                                                    dStatus >= 2 ?
                                                        <div className="order-tracking completed">
                                                            <span className="is-complete"></span>
                                                            <p>Waiting for<br />
                                                                proposal</p>
                                                        </div>
                                                        :
                                                        <div className="order-tracking completed hidden">
                                                            <span className="is-complete hiddens"></span>
                                                            <p>Waiting for<br />
                                                                proposal</p>
                                                        </div>
                                                }

                                                {
                                                    dStatus >= 4 ?
                                                        <div className="order-tracking completed">
                                                            <span className="is-complete"></span>
                                                            <p>Translation<br />
                                                                progress</p>
                                                        </div>
                                                        :
                                                        <div className="order-tracking completed hidden">
                                                            <span className="is-complete hiddens"></span>
                                                            <p>Translation<br />
                                                                progress</p>
                                                        </div>
                                                }

                                                {
                                                    dStatus >= 5 ?
                                                        <div className="order-tracking completed">
                                                            <span className="is-complete"></span>
                                                            <p>Under<br />review</p>
                                                        </div>
                                                        :
                                                        <div className="order-tracking completed hidden">
                                                            <span className="is-complete hiddens"></span>
                                                            <p>Under<br />review</p>
                                                        </div>
                                                }

                                                {
                                                    dStatus >= 6 ?
                                                        <div className="order-tracking completed">
                                                            <span className="is-complete"></span>
                                                            <p>Completed</p>
                                                        </div>
                                                        :
                                                        <div className="order-tracking hidden">
                                                            <span className="is-complete hiddens"></span>
                                                            <p>Completed</p>
                                                        </div>
                                                }


                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            {
                                documentsCount == candidatesDocumentsCount && dStatus == 0 &&
                                <>
                                    <div className="alert alert-success" role="alert"><i data-acorn-icon="check-circle" className="pin" data-acorn-size="26"></i> All documents have been submitted for verification</div>
                                </>
                            }

                            <div id="reject-warn" className="alert alert-danger d-none" role="alert"><i data-acorn-icon="shield-warning" className="pin" data-acorn-size="26"></i> One or more documents rejected,please re upload documents</div>

                            <div className="row mb-2">
                                <div className='col-sm-12 col-md-12 col-lg-12'>

                                    <div class="card mb-5">
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Document</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        uploadDocuments.map((d, k) =>
                                                            myProfile.candidate_family_status == 'm' && d.document_name == 'marriage certificate' ?
                                                                <tr>
                                                                    <th scope="row">{k + 1}</th>
                                                                    <td>
                                                                        <a
                                                                            href="#"
                                                                            className="justify-content-center"
                                                                            style={{ textTransform: 'capitalize', color: '#4a4a4a' }}
                                                                        >
                                                                            {d.document_name}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <div className="col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4" style={{ width: '100%' }}>
                                                                            <div className={"lh-1 text-alternate d-none " + d.id + "_v " + d.id + '_l'}>Uploaded</div>
                                                                            <span className={"badge bg-danger text-uppercase d-none r" + d.id} data-toggle="tooltip" data-placement="top" title="" style={{ width: '60px' }}>Rejected</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 " + d.id}>

                                                                            <input id={"f_" + d.id} accept="application/pdf" type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e, d.document_name, d.id, d.order)} />

                                                                            {
                                                                                dStatus != 6 &&
                                                                                <label htmlFor={"f_" + d.id} className="badge bg-outline-primary group">UPLOAD</label>
                                                                            }

                                                                        </div>



                                                                        <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 d-none " + d.id + "_v " + d.id + '_l'} onClick={() => _view(d)}>
                                                                            <span className="badge bg-outline-primary group" style={{ cursor: 'pointer' }}>VIEW</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <th scope="row">{k + 1}</th>
                                                                    <td>
                                                                        <a
                                                                            href="#"
                                                                            className="justify-content-center"
                                                                            style={{ textTransform: 'capitalize', color: '#4a4a4a' }}
                                                                        >
                                                                            {d.document_name}

                                                                            {
                                                                                d.id == "F22" &&
                                                                                <a target="_blank" href="https://hireandcare.de/documents/students/PowerofAttorneyausbildung.docx">Click Here to Download</a>
                                                                            }

                                                                            {
                                                                                d.id == "F23" &&
                                                                                <a target="_blank" href="https://hireandcare.de/documents/students/Antrag_Anerkennung_Bildungsnachweise.pdf">Click Here to Download</a>
                                                                            }

                                                                            {
                                                                                d.id == "F10" &&
                                                                                <a target="_blank" href="https://hireandcare.de/documents/nurse/PowerofAttorney-Nurse.docx">Click Here to Download</a>
                                                                            }

                                                                            {
                                                                                d.id == "F9" &&
                                                                                <a target="_blank" href="https://hireandcare.de/documents/nurse/LPA_BerBez-Ausl-KiKrPf-nurse.pdf">Click Here to Download</a>
                                                                            }


                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <div className={"justify-content-center"}>
                                                                            <div className={"lh-1 text-alternate d-none " + d.id + "_v " + d.id + '_l'}>Uploaded</div>
                                                                            <span className={"badge bg-danger text-uppercase d-none r" + d.id} data-toggle="tooltip" data-placement="top" title="" style={{ width: '60px' }}>Rejected</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 " + d.id}>
                                                                            {
                                                                                d.id == 'F22' || d.id == 'F10' ?
                                                                                    <input id={"f_" + d.id} accept=".doc, .docx," type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e, d.document_name, d.id, d.order)} />
                                                                                    :
                                                                                    <input id={"f_" + d.id} accept="application/pdf" type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e, d.document_name, d.id, d.order)} />
                                                                            }

                                                                            {
                                                                                dStatus != 6 &&
                                                                                <label htmlFor={"f_" + d.id} className="badge bg-outline-primary group">UPLOAD</label>
                                                                            }
                                                                        </div>

                                                                        <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 d-none " + d.id + "_v " + d.id + '_l'} onClick={() => _view(d)}>
                                                                            <span className="badge bg-outline-primary group" style={{ cursor: 'pointer' }}>VIEW</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/*
                                    <div className="mb-4 mb-lg-3 bg-transparent no-shadow d-none d-lg-block">
                                        <div className="row g-0">
                                            <div className="col-auto sw-11 d-none d-lg-flex"></div>
                                            <div className="col">
                                                <div className="ps-5 pe-4 h-100">
                                                    <div className="row g-0 h-100 align-content-center custom-sort">
                                                        <div className="col-lg-4 d-flex flex-column mb-lg-0 pe-3 d-flex">
                                                            <div className="text-muted text-small cursor-pointer sort" data-sort="name">DOCUMENT</div>
                                                        </div>
                                                        <div className="col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                            <div className="text-muted text-small cursor-pointer sort" data-sort="email"></div>
                                                        </div>
                                                        <div className="col-lg-3 d-flex flex-column pe-1 justify-content-center">
                                                            <div className="text-muted text-small cursor-pointer sort" data-sort="phone"></div>
                                                        </div>
                                                        <div className="col-lg-2 d-flex flex-column pe-1 justify-content-center">
                                                            <div className="text-muted text-small cursor-pointer sort" data-sort="group">STATUS</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    */}




                                    {/*CARD START
                                    {
                                        uploadDocuments.map((d, k) =>

                                            myProfile.candidate_family_status == 'm' && d.document_name == 'marriage certificate' ?
                                                <div className="card mb-2">
                                                    <div className="row g-0 h-100 sh-lg-9 position-relative">
                                                        <a href="Products.Detail.html" className="col-auto position-relative">
                                                            <img src={dimage} className='card-img card-img-horizontal sw-11 h-100' />
                                                        </a>
                                                        <div className="col py-4 py-lg-0">
                                                            <div className="ps-5 pe-4 h-100">
                                                                <div className="row g-0 h-100 align-content-center">
                                                                    <a
                                                                        href="#"
                                                                        className="col-11 col-lg-4 d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center"
                                                                        style={{ textTransform: 'capitalize', color: '#4a4a4a' }}
                                                                    >
                                                                        {d.document_name}
                                                                        <div className="text-small text-muted text-truncate position">*Upload pdf format</div>
                                                                    </a>
                                                                    <div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                                                                        <div className="lh-1 text-alternate"></div>
                                                                    </div>
                                                                    <div className="col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                                                                        <div className={"lh-1 text-alternate d-none " + d.id + "_v " + d.id + '_l'}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="acorn-icons acorn-icons-check mb-3 d-inline-block text-primary"><path d="M16 5L7.7051 14.2166C7.32183 14.6424 6.65982 14.6598 6.2547 14.2547L3 11"></path></svg></div>
                                                                        <span className={"badge bg-danger text-uppercase d-none r" + d.id} data-toggle="tooltip" data-placement="top" title="" style={{ width: '60px' }}>Rejected</span>
                                                                    </div>
                                                                    <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 " + d.id}>

                                                                        <input id={"f_" + d.id} accept="application/pdf" type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e, d.document_name, d.id)} />

                                                                        {
                                                                            dStatus != 6 &&
                                                                            <label htmlFor={"f_" + d.id} className="badge bg-outline-primary group">UPLOAD</label>
                                                                        }

                                                                    </div>



                                                                    <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 d-none " + d.id + "_v " + d.id + '_l'} onClick={() => _view(d)}>
                                                                        <span className="badge bg-outline-primary group" style={{ marginTop: 28, cursor: 'pointer' }}>VIEW</span>
                                                                    </div>

                                                                    <div className="col-1 d-flex flex-column mb-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                d.document_name != 'marriage certificate' &&
                                                <div className="card mb-2">
                                                    <div className="row g-0 h-100 sh-lg-9 position-relative">
                                                        <a href="Products.Detail.html" className="col-auto position-relative">
                                                            <img src={dimage} className='card-img card-img-horizontal sw-11 h-100' />
                                                        </a>
                                                        <div className="col py-4 py-lg-0">
                                                            <div className="ps-5 pe-4 h-100">
                                                                <div className="row g-0 h-100 align-content-center">
                                                                    <a
                                                                        href="#"
                                                                        className="col-11 col-lg-4 d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center"
                                                                        style={{ textTransform: 'capitalize', color: '#4a4a4a' }}
                                                                    >
                                                                        {d.document_name}
                                                                        <div className="text-small text-muted text-truncate position">*Upload pdf format</div>
                                                                    </a>
                                                                    <div className="col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                                                                        <div className="lh-1 text-alternate">&nbsp;</div>
                                                                    </div>
                                                                    <div className={"col-12 col-lg-3 d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4"}>
                                                                        <div className={"lh-1 text-alternate d-none " + d.id + "_v " + d.id + '_l'}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="acorn-icons acorn-icons-check mb-3 d-inline-block text-primary"><path d="M16 5L7.7051 14.2166C7.32183 14.6424 6.65982 14.6598 6.2547 14.2547L3 11"></path></svg></div>
                                                                        <span className={"badge bg-danger text-uppercase d-none r" + d.id} data-toggle="tooltip" data-placement="top" title="" style={{ width: '60px' }}>Rejected</span>
                                                                    </div>
                                                                    <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 " + d.id}>

                                                                        <input id={"f_" + d.id} accept="application/pdf" type="file" style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, filter: 'alpha(opacity=0)', msFilter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)", opacity: 0, backgroundColor: 'transparent', color: 'transparent' }} name="file_source" size="40" onChange={(e) => uploadFile(e, d.document_name, d.id)} />
                                                                        {
                                                                            dStatus != 6 &&
                                                                            <label htmlFor={"f_" + d.id} className="badge bg-outline-primary group">UPLOAD</label>
                                                                        }
                                                                    </div>

                                                                    <div className={"col-12 col-lg-2 d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5 d-none " + d.id + "_v " + d.id + '_l'} onClick={() => _view(d)}>
                                                                        <span className="badge bg-outline-primary group" style={{ marginTop: 28, cursor: 'pointer' }}>VIEW</span>
                                                                    </div>

                                                                    <div className="col-1 d-flex flex-column mb-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    }

                                    {/*CARD END*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Documents;