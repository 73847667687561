import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';

import { get, post, put, deleteM } from "../../utility/Adapter";

const ExportCv = () => {

    const [documentId, setDocumentId] = useState('');
    const [candidateId, setCandidateId] = useState('');

    useEffect(() => {
        //_getDocumentId();
        _export_cv();
    }, []);

    const _export_cv = () => {
        get('candidates/export_cv').then(async function (response) {
            setCandidateId(response.data.data);
        });
    }

    const _getDocumentId = () => {
        get('candidates/document_id').then(async function (response) {
            setDocumentId(response.data.data);
        });
    }

    const exportDoc = () => {
        window.open(
            process.env.REACT_APP_BASE_URL + 'candidates/download/' + candidateId,
            '_blank'
        );
    }


    return (
        <>
            <Header />

            <main>
                <div className="container">
                    <div className="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}

                        {/* Page Content Start */}
                        <div className="col">
                            {/* Title and Top Buttons Start */}
                            <div className="page-title-container mb-3">
                                <div className="row">
                                    <div className="col mb-2">
                                        <h1 className="mb-2 pb-0 display-4" id="title">Export CV</h1>
                                        <div className="text-muted font-heading text-small"></div>
                                    </div>

                                    <div className="col-12 col-sm-auto d-flex align-items-center justify-content-end">
                                        <button onClick={() => exportDoc()} type="button" className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto" data-bs-toggle="modal" data-bs-target="#largeRightModalExample">
                                            <i data-acorn-icon="arrow-end-bottom"></i>
                                            <span>Export</span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}

                            {
                                candidateId != '' &&
                                <div className='row'>
                                    <div className='col-sm-12 mt-6'>
                                        <embed src={process.env.REACT_APP_BASE_URL + 'candidates/download/' + candidateId} type="application/pdf" width={'100%'} height={800} />
                                    </div>
                                </div>
                            }

                            {
                                candidateId == '' &&
                                <div className="alert alert-danger text-center" role="alert">
                                    No Documents Found!
                                </div>
                            }




                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ExportCv;