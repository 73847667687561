import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import Cookies from 'js-cookie';

import logo from '../../images/logo.svg';
import { get, post, put, deleteM } from "../../utility/Adapter";
import { createError, removeErrors } from "../../utility/Validation";

const Login = () => {

    useEffect(() => {
        Cookies.remove('hcUser');
        Cookies.remove('hcUserData');

        //window.location = 'https://hireandcare.de/login';

    }, []);

    Element.prototype.insertAfter = function (newEl) {
        this.parentNode.insertBefore(newEl, this.nextSibling);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const signin = () => {
        var email = document.getElementById('email').value;
        var password = document.getElementById('password').value;

        if (!validateEmail(email)) {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter valid email address";
            document.getElementById('email').insertAfter(errorElement);
            return false;
        }

        if (password == "") {
            var errorElement = document.createElement("div");
            errorElement.classList.add('error');
            errorElement.innerHTML = "Please enter password";
            document.getElementById('password').insertAfter(errorElement);
            return false;
        }

        var raw = JSON.stringify({
            "email": email,
            "password": password
        });

        post('candidates/login', raw).then(async function (response) {
            
            if (response.data.data.token) {
                Cookies.set('hcUser', response.data.data.token);
                window.location = '/profile';
            }

        }).catch(function (error) {
            //console.log(error)
            if (error.response.data) {
                if (error.response.data.error_code == 100) {
                    createError(document.getElementById('email'), error.response.data.error_message);
                }
                else if (error.response.data.error_code == 101) {
                    createError(document.getElementById('password'), error.response.data.error_message);
                }
            }
        });
    }


    return (
        <>
            <div className="h-100">
                <div className="fixed-background"></div>
                <div className="container-fluid p-0 h-100 position-relative">
                    <div className="row g-0 h-100">
                        <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
                            <div className="min-h-100 d-flex align-items-center">
                                <div className="w-100 w-lg-75 w-xxl-50">
                                    <div>
                                        <div className="mb-5">
                                            <h1 className="display-3 text-white">Hire and Care</h1>
                                            <h1 className="display-3 text-white">Login</h1>
                                        </div>
                                        <p className="h6 text-white lh-1-5 mb-5">
                                            Dynamically target high-payoff intellectual capital for customized technologies. Objectively integrate emerging core competencies before
                                            process-centric communities...
                                        </p>
                                        <div className="mb-5">
                                            <a className="btn btn-lg btn-outline-white" href="/">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
                            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                                <div className="sw-lg-50 px-5">
                                    <div className="sh-11">
                                        <a href="index.html">
                                            <div className="logo-default">
                                                <img src={logo} alt="logo" style={{ width: 180 }} />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="mb-5">
                                        <h2 className="cta-1 mb-0 text-primary">Welcome,</h2>
                                        <h2 className="cta-1 text-primary">let's get started!</h2>
                                    </div>
                                    <div className="mb-5">
                                        <p className="h6">Please use your credentials to login.</p>
                                    </div>
                                    <div>
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <i data-acorn-icon="email"></i>
                                            <input className="form-control" placeholder="Email" name="email" id="email"/>
                                        </div>
                                        <div className="mb-3 filled form-group tooltip-end-top">
                                            <i data-acorn-icon="lock-off"></i>
                                            <input className="form-control pe-7" name="password" type="password" placeholder="Password" id="password"/>
                                            {/*<a className="text-small position-absolute t-3 e-3" href="ja">Forgot?</a>*/}
                                        </div>
                                        <button type="submit" className="btn btn-lg btn-primary" onClick={() => signin()}>Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;