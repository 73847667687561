import React, { useState, useHook, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const SideBar = (props) => {
  return (
    <div class="col-12 col-lg-3 mb-5">
      <div class="card">
        <div class="card-body">
          <div class="row g-0">
            <div class="col-auto sw-5 d-flex flex-column justify-content-center align-items-center position-relative">
              <div class="w-100 d-flex h-0"></div>
              <div class="sw-5 sh-5 rounded-xl d-flex flex-shrink-0 justify-content-center align-items-center">
                <div class="bg-gradient-light sw-5 sh-5 rounded-xl">
                  <div class="text-white d-flex justify-content-center align-items-center h-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="acorn-icons acorn-icons-cupcake undefined"
                    >
                      <path d="M17 5L10.6329 12.2032C10.2511 12.6351 9.58418 12.6556 9.17656 12.248L6.92857 10"></path>
                      <path d="M11 2L5.5 2C4.09554 2 3.39331 2 2.88886 2.33706C2.67048 2.48298 2.48298 2.67048 2.33706 2.88886C2 3.39331 2 4.09554 2 5.5L2 14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18L14.5 18C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5L18 11"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="w-100 d-flex h-100 justify-content-center position-relative">
                <div class="line-w-1 bg-separator h-100 position-absolute"></div>
              </div>
            </div>
            <div class="col mb-4 ps-4">
              <div class="h-100">
                <div class="d-flex flex-column justify-content-start">
                  <div class="d-flex flex-column">
                    <a href="/profile" class="heading pt-1">
                      Resume Creation
                    </a>
                    <div class="text-muted mt-1">
                      Create an awesome resume in a few easy steps.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-0">
            <div class="col-auto sw-5 d-flex flex-column justify-content-center align-items-center position-relative">
              <div class="w-100 d-flex h-0"></div>
              <div class="sw-5 sh-5 rounded-xl d-flex flex-shrink-0 justify-content-center align-items-center">
                <div
                  className={
                    props.step >= 1
                      ? "bg-gradient-light sw-5 sh-5 rounded-xl"
                      : "bg-gradient-inactive sw-5 sh-5 rounded-xl"
                  }
                >
                  <div class="text-white d-flex justify-content-center align-items-center h-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="acorn-icons acorn-icons-cupcake undefined"
                    >
                      {props.step >= 1 ? (
                        <>
                          <path d="M17 5L10.6329 12.2032C10.2511 12.6351 9.58418 12.6556 9.17656 12.248L6.92857 10"></path>
                          <path d="M11 2L5.5 2C4.09554 2 3.39331 2 2.88886 2.33706C2.67048 2.48298 2.48298 2.67048 2.33706 2.88886C2 3.39331 2 4.09554 2 5.5L2 14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18L14.5 18C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5L18 11"></path>
                        </>
                      ) : (
                        <>
                          <path d="M9 2.57735C9.6188 2.22008 10.3812 2.22008 11 2.57735L15.9282 5.42265C16.547 5.77992 16.9282 6.44017 16.9282 7.1547V12.8453C16.9282 13.5598 16.547 14.2201 15.9282 14.5774L11 17.4226C10.3812 17.7799 9.6188 17.7799 9 17.4226L4.0718 14.5774C3.45299 14.2201 3.0718 13.5598 3.0718 12.8453V7.1547C3.0718 6.44017 3.45299 5.77992 4.0718 5.42265L9 2.57735Z"></path>
                          <path d="M8 12 12.0001 7.99994M8 7.99994 12.0001 12"></path>
                        </>
                      )}
                    </svg>
                  </div>
                </div>
              </div>
              <div class="w-100 d-flex h-100 justify-content-center position-relative">
                <div class="line-w-1 bg-separator h-100 position-absolute"></div>
              </div>
            </div>
            <div class="col mb-4 ps-4">
              <div class="h-100">
                <div class="d-flex flex-column justify-content-start">
                  <div class="d-flex flex-column">
                    <a
                      href={props.step >= 1 ? "/documents" : "#"}
                      className={
                        props.step >= 1
                          ? "heading pt-1"
                          : "text-muted heading pt-1 blk-c"
                      }
                    >
                      Upload Documents
                    </a>
                    <div class="text-muted mt-1">
                      Upload your educational certificates and documents, it's
                      easy.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            props.license != 'Y' &&
            <>
              <div class="row g-0">
                <div class="col-auto sw-5 d-flex flex-column justify-content-center align-items-center position-relative">
                  <div class="w-100 d-flex h-0"></div>
                  <div class="sw-5 sh-5 rounded-xl d-flex flex-shrink-0 justify-content-center align-items-center">
                    <div
                      className={
                        props.step >= 2
                          ? "bg-gradient-light sw-5 sh-5 rounded-xl"
                          : "bg-gradient-inactive sw-5 sh-5 rounded-xl"
                      }
                    >
                      <div class="text-white d-flex justify-content-center align-items-center h-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="acorn-icons acorn-icons-cupcake undefined"
                        >
                          {props.step >= 2 ? (
                            <>
                              <path d="M17 5L10.6329 12.2032C10.2511 12.6351 9.58418 12.6556 9.17656 12.248L6.92857 10"></path>
                              <path d="M11 2L5.5 2C4.09554 2 3.39331 2 2.88886 2.33706C2.67048 2.48298 2.48298 2.67048 2.33706 2.88886C2 3.39331 2 4.09554 2 5.5L2 14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18L14.5 18C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5L18 11"></path>
                            </>
                          ) : (
                            <>
                              <path d="M9 2.57735C9.6188 2.22008 10.3812 2.22008 11 2.57735L15.9282 5.42265C16.547 5.77992 16.9282 6.44017 16.9282 7.1547V12.8453C16.9282 13.5598 16.547 14.2201 15.9282 14.5774L11 17.4226C10.3812 17.7799 9.6188 17.7799 9 17.4226L4.0718 14.5774C3.45299 14.2201 3.0718 13.5598 3.0718 12.8453V7.1547C3.0718 6.44017 3.45299 5.77992 4.0718 5.42265L9 2.57735Z"></path>
                              <path d="M8 12 12.0001 7.99994M8 7.99994 12.0001 12"></path>
                            </>
                          )}
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 d-flex h-100 justify-content-center position-relative">
                    <div class="line-w-1 bg-separator h-100 position-absolute"></div>
                  </div>
                </div>
                <div class="col mb-4 ps-4">
                  <div class="h-100">
                    <div class="d-flex flex-column justify-content-start">
                      <div class="d-flex flex-column">
                        <a
                          href={props.step >= 2 ? "/documents" : "#"}
                          className={
                            props.step >= 2
                              ? "heading pt-1"
                              : "text-muted heading pt-1 blk-c"
                          }
                        >
                          Waiting for payment
                        </a>
                        <div class="text-muted mt-1">
                          Approve the translation quote and pay for it.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row g-0">
                <div class="col-auto sw-5 d-flex flex-column justify-content-center align-items-center position-relative">
                  <div class="w-100 d-flex h-0"></div>
                  <div class="sw-5 sh-5 rounded-xl d-flex flex-shrink-0 justify-content-center align-items-center">
                    <div
                      className={
                        props.step >= 3
                          ? "bg-gradient-light sw-5 sh-5 rounded-xl"
                          : "bg-gradient-inactive sw-5 sh-5 rounded-xl"
                      }
                    >
                      <div class="text-white d-flex justify-content-center align-items-center h-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="acorn-icons acorn-icons-pepper undefined"
                        >
                          {props.step >= 3 ? (
                            <>
                              <path d="M17 5L10.6329 12.2032C10.2511 12.6351 9.58418 12.6556 9.17656 12.248L6.92857 10"></path>
                              <path d="M11 2L5.5 2C4.09554 2 3.39331 2 2.88886 2.33706C2.67048 2.48298 2.48298 2.67048 2.33706 2.88886C2 3.39331 2 4.09554 2 5.5L2 14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18L14.5 18C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5L18 11"></path>
                            </>
                          ) : (
                            <>
                              <path d="M9 2.57735C9.6188 2.22008 10.3812 2.22008 11 2.57735L15.9282 5.42265C16.547 5.77992 16.9282 6.44017 16.9282 7.1547V12.8453C16.9282 13.5598 16.547 14.2201 15.9282 14.5774L11 17.4226C10.3812 17.7799 9.6188 17.7799 9 17.4226L4.0718 14.5774C3.45299 14.2201 3.0718 13.5598 3.0718 12.8453V7.1547C3.0718 6.44017 3.45299 5.77992 4.0718 5.42265L9 2.57735Z"></path>
                              <path d="M8 12 12.0001 7.99994M8 7.99994 12.0001 12"></path>
                            </>
                          )}
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 d-flex h-100 justify-content-center position-relative">
                    <div class="line-w-1 bg-separator h-100 position-absolute"></div>
                  </div>
                </div>
                <div class="col mb-4 ps-4">
                  <div class="h-100">
                    <div class="d-flex flex-column justify-content-start">
                      <div class="d-flex flex-column">
                        <a
                          href={props.step >= 3 ? "/documents" : "#"}
                          className={
                            props.step >= 3
                              ? "heading pt-1"
                              : "text-muted heading pt-1 blk-c"
                          }
                        >
                          Translation progress
                        </a>
                        <div class="text-muted mt-1">
                          Our documents are currently being translated.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row g-0">
                <div class="col-auto sw-5 d-flex flex-column justify-content-center align-items-center position-relative">
                  <div class="w-100 d-flex h-0"></div>
                  <div class="sw-5 sh-5 rounded-xl d-flex flex-shrink-0 justify-content-center align-items-center">
                    <div
                      className={
                        props.step >= 4
                          ? "bg-gradient-light sw-5 sh-5 rounded-xl"
                          : "bg-gradient-inactive sw-5 sh-5 rounded-xl"
                      }
                    >
                      <div class="text-white d-flex justify-content-center align-items-center h-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="acorn-icons acorn-icons-pepper undefined"
                        >
                          {props.step >= 4 ? (
                            <>
                              <path d="M17 5L10.6329 12.2032C10.2511 12.6351 9.58418 12.6556 9.17656 12.248L6.92857 10"></path>
                              <path d="M11 2L5.5 2C4.09554 2 3.39331 2 2.88886 2.33706C2.67048 2.48298 2.48298 2.67048 2.33706 2.88886C2 3.39331 2 4.09554 2 5.5L2 14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18L14.5 18C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5L18 11"></path>
                            </>
                          ) : (
                            <>
                              <path d="M9 2.57735C9.6188 2.22008 10.3812 2.22008 11 2.57735L15.9282 5.42265C16.547 5.77992 16.9282 6.44017 16.9282 7.1547V12.8453C16.9282 13.5598 16.547 14.2201 15.9282 14.5774L11 17.4226C10.3812 17.7799 9.6188 17.7799 9 17.4226L4.0718 14.5774C3.45299 14.2201 3.0718 13.5598 3.0718 12.8453V7.1547C3.0718 6.44017 3.45299 5.77992 4.0718 5.42265L9 2.57735Z"></path>
                              <path d="M8 12 12.0001 7.99994M8 7.99994 12.0001 12"></path>
                            </>
                          )}
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 d-flex h-100 justify-content-center position-relative">
                    <div class="line-w-1 bg-separator h-100 position-absolute"></div>
                  </div>
                </div>
                <div class="col mb-4 ps-4">
                  <div class="h-100">
                    <div class="d-flex flex-column justify-content-start">
                      <div class="d-flex flex-column">
                        <a
                          href={props.step >= 4 ? "/documents/verify" : "#"}
                          className={
                            props.step >= 4
                              ? "heading pt-1"
                              : "text-muted heading pt-1 blk-c"
                          }
                        >
                          Verify Documents
                        </a>
                        <div class="text-muted mt-1">
                          Verify and double-check your data in the translated
                          documents.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }


          <div class="row g-0">
            <div class="col-auto sw-5 d-flex flex-column justify-content-center align-items-center position-relative">
              <div class="w-100 d-flex h-0"></div>
              <div class="sw-5 sh-5 rounded-xl d-flex flex-shrink-0 justify-content-center align-items-center">
                <div
                  className={
                    props.step >= 5
                      ? "bg-gradient-light sw-5 sh-5 rounded-xl"
                      : "bg-gradient-inactive sw-5 sh-5 rounded-xl"
                  }
                >
                  <div class="text-white d-flex justify-content-center align-items-center h-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="acorn-icons acorn-icons-loaf undefined"
                    >
                      {props.step >= 5 ? (
                        <>
                          <path d="M17 5L10.6329 12.2032C10.2511 12.6351 9.58418 12.6556 9.17656 12.248L6.92857 10"></path>
                          <path d="M11 2L5.5 2C4.09554 2 3.39331 2 2.88886 2.33706C2.67048 2.48298 2.48298 2.67048 2.33706 2.88886C2 3.39331 2 4.09554 2 5.5L2 14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18L14.5 18C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5L18 11"></path>
                        </>
                      ) : (
                        <>
                          <path d="M9 2.57735C9.6188 2.22008 10.3812 2.22008 11 2.57735L15.9282 5.42265C16.547 5.77992 16.9282 6.44017 16.9282 7.1547V12.8453C16.9282 13.5598 16.547 14.2201 15.9282 14.5774L11 17.4226C10.3812 17.7799 9.6188 17.7799 9 17.4226L4.0718 14.5774C3.45299 14.2201 3.0718 13.5598 3.0718 12.8453V7.1547C3.0718 6.44017 3.45299 5.77992 4.0718 5.42265L9 2.57735Z"></path>
                          <path d="M8 12 12.0001 7.99994M8 7.99994 12.0001 12"></path>
                        </>
                      )}
                    </svg>
                  </div>
                </div>
              </div>
              <div class="w-100 d-flex h-100 justify-content-center position-relative"></div>
            </div>
            <div class="col ps-4">
              <div class="h-100">
                <div class="d-flex flex-column justify-content-start">
                  <div class="d-flex flex-column">
                    <a
                      href={props.step >= 5 ? "/documents/verify" : "#"}
                      className={
                        props.step >= 5
                          ? "heading pt-1"
                          : "text-muted heading pt-1 blk-c"
                      }
                    >
                      Done
                    </a>
                    <div class="text-muted mt-1">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  /*return (
        <>
            <div className="col-auto d-none d-lg-flex">
                <ul className="sw-25 side-menu mb-0 primary" id="menuSide">
                    <li>
                        <a href="#" data-bs-target="#dashboard">
                            <i data-acorn-icon="home" className="icon" data-acorn-size="18"></i>
                            <span className="label">Dashboard</span>
                        </a>
                        <ul>
                            <li>
                                <a href="/profile">
                                    <i data-acorn-icon="navigate-diagonal" className="icon d-none" data-acorn-size="18"></i>
                                    <span className="label">Profile</span>
                                </a>
                            </li>
                            <li>
                                <a href="/documents">
                                    <i data-acorn-icon="file-data" className="icon d-none" data-acorn-size="18"></i>
                                    <span className="label">Documents</span>
                                </a>
                            </li>
                            <li>
                                <a href="/export_cv">
                                    <i data-acorn-icon="arrow-end-top" className="icon d-none" data-acorn-size="18"></i>
                                    <span className="label">Export CV</span>
                                </a>
                            </li>
                            <li>
                                <a href="/power_of_attorney">
                                    <i data-acorn-icon="file-text" className="icon d-none" data-acorn-size="18"></i>
                                    <span className="label">Power of attorney</span>
                                </a>
                            </li>
                            <li>
                                <a href="/application">
                                    <i data-acorn-icon="file-empty" className="icon d-none" data-acorn-size="18"></i>
                                    <span className="label">Application</span>
                                </a>
                            </li>
                            <li>
                                <a href="/jobs_enquiry">
                                    <i data-acorn-icon="search" className="icon d-none" data-acorn-size="18"></i>
                                    <span className="label">Jobs Enquiry</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    )*/
};

export default SideBar;
