export const createError = (element, error) => {
    var errorElement = document.createElement("div");
    errorElement.classList.add('input-error');
    errorElement.innerHTML = '* '+error;
    element.insertAfter(errorElement);
    element.focus();
}

export const removeErrors = () => {
    var ele = document.querySelectorAll(".input-error");
        
    for (var j = 0; j < ele.length; j++) {
        ele[j].remove();
    }
}