import React from 'react';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Cookies from 'js-cookie';

import Index from './pages/dashboard/Index';
//import Products from './pages/products/Index';
import Verify from './pages/documents/Verify';
import Profile from './pages/dashboard/Profile';
import ProfileNew from './pages/dashboard/ProfileNew';
import Documents from './pages/documents/Index';
import ExportCv from './pages/documents/ExportCv';
import Login from './pages/login/Index';
import Logout from './pages/login/Logout';
import Enquiries from './pages/enquiries/Index';

const fakeAuthTranslator = {
  isAuthenticated: false,

  authenticate() {
    if (Cookies.get('hcUser') == undefined) {
      this.isAuthenticated = false;
    }
    else {
      this.isAuthenticated = true;
    }

    return this.isAuthenticated;
  },
  signout() {
    this.isAuthenticated = false
    //setTimeout(cb, 100) // fake async
  }
}
const PrivateRoute = ({ children }) => {
  const authed = fakeAuthTranslator.authenticate() // isauth() returns true or false based on localStorage
  
  return authed ? children : window.location="https://hireandcare.de/login";
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="/enquiries" element={<PrivateRoute><Enquiries /></PrivateRoute>} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      {/*<Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />*/}
      <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="/documents/verify" element={<PrivateRoute><Verify /></PrivateRoute>} />
      <Route path="/documents" element={<PrivateRoute><Documents /></PrivateRoute>} />
      <Route path="/export_cv" element={<PrivateRoute><ExportCv /></PrivateRoute>} />
      
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
