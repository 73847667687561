
const Footer = () => {
    return (
        <>
            {/* Layout Footer Start */}
            <footer>
                <div className="footer-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <p className="mb-0 text-muted text-medium">&copy; <a href="https://hireandcare.de">hire and care</a></p>
                            </div>
                            <div className="col-sm-6 d-none d-sm-block">
                                <ul className="breadcrumb pt-0 pe-0 mb-0 float-end">
                                    {/*<li className="breadcrumb-item mb-0 text-medium">
                                        <a href="https://voxcodes.in" target="_blank" className="btn-link">Powered By VoxUnico</a>
                                    </li>
                                    <li className="breadcrumb-item mb-0 text-medium">
                                        <a href="https://voxcodes.in/report" target="_blank" className="btn-link">Report Bug</a>
    </li>*/}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer >
            {/* Layout Footer End </div>*/}


            {/* Niches Modal Start */}
            <div
                className="modal fade modal-right scroll-out-negative"
                id="niches"
                data-bs-backdrop="true"
                tabindex="-1"
                role="dialog"
                aria-labelledby="niches"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable full" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Niches</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <div className="scroll-track-visible">
                                <div className="mb-5">
                                    <label className="mb-2 d-inline-block form-label">Classic Dashboard</label>
                                    <div className="hover-reveal-buttons position-relative hover-reveal cursor-default">
                                        <div className="position-relative mb-3 mb-lg-5 rounded-sm">
                                            <img
                                                src="https://acorn.coloredstrategies.com/img/page/classic-dashboard.webp"
                                                className="img-fluid rounded-sm lower-opacity border border-separator-light"
                                                alt="card image"
                                            />
                                            <div className="position-absolute reveal-content rounded-sm absolute-center-vertical text-center w-100">
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-html-classic-dashboard.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Html
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-laravel-classic-dashboard.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Laravel
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-dotnet-classic-dashboard.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    .Net5
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <label className="mb-2 d-inline-block form-label">Medical Assistant</label>
                                    <div className="hover-reveal-buttons position-relative hover-reveal cursor-default">
                                        <div className="position-relative mb-3 mb-lg-5 rounded-sm">
                                            <img
                                                src="https://acorn.coloredstrategies.com/img/page/medical-assistant.webp"
                                                className="img-fluid rounded-sm lower-opacity border border-separator-light"
                                                alt="card image"
                                            />
                                            <div className="position-absolute reveal-content rounded-sm absolute-center-vertical text-center w-100">
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-html-medical-assistant.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Html
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-laravel-medical-assistant.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Laravel
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-dotnet-medical-assistant.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    .Net5
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <label className="mb-2 d-inline-block form-label">Service Provider</label>
                                    <div className="hover-reveal-buttons position-relative hover-reveal cursor-default">
                                        <div className="position-relative mb-3 mb-lg-5 rounded-sm">
                                            <img
                                                src="https://acorn.coloredstrategies.com/img/page/service-provider.webp"
                                                className="img-fluid rounded-sm lower-opacity border border-separator-light"
                                                alt="card image"
                                            />
                                            <div className="position-absolute reveal-content rounded-sm absolute-center-vertical text-center w-100">
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-html-service-provider.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Html
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-laravel-service-provider.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Laravel
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-dotnet-service-provider.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    .Net5
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <label className="mb-2 d-inline-block form-label">Elearning Portal</label>
                                    <div className="hover-reveal-buttons position-relative hover-reveal cursor-default">
                                        <div className="position-relative mb-3 mb-lg-5 rounded-sm">
                                            <img
                                                src="https://acorn.coloredstrategies.com/img/page/elearning-portal.webp"
                                                className="img-fluid rounded-sm lower-opacity border border-separator-light"
                                                alt="card image"
                                            />
                                            <div className="position-absolute reveal-content rounded-sm absolute-center-vertical text-center w-100">
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-html-elearning-portal.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Html
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-laravel-elearning-portal.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Laravel
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-dotnet-elearning-portal.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    .Net5
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <label className="mb-2 d-inline-block form-label">Ecommerce Platform</label>
                                    <div className="hover-reveal-buttons position-relative hover-reveal cursor-default">
                                        <div className="position-relative mb-3 mb-lg-5 rounded-sm">
                                            <img
                                                src="https://acorn.coloredstrategies.com/img/page/ecommerce-platform.webp"
                                                className="img-fluid rounded-sm lower-opacity border border-separator-light"
                                                alt="card image"
                                            />
                                            <div className="position-absolute reveal-content rounded-sm absolute-center-vertical text-center w-100">
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-html-ecommerce-platform.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Html
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-laravel-ecommerce-platform.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Laravel
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-dotnet-ecommerce-platform.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    .Net5
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <label className="mb-2 d-inline-block form-label">Starter Project</label>
                                    <div className="hover-reveal-buttons position-relative hover-reveal cursor-default">
                                        <div className="position-relative mb-3 mb-lg-5 rounded-sm">
                                            <img
                                                src="https://acorn.coloredstrategies.com/img/page/starter-project.webp"
                                                className="img-fluid rounded-sm lower-opacity border border-separator-light"
                                                alt="card image"
                                            />
                                            <div className="position-absolute reveal-content rounded-sm absolute-center-vertical text-center w-100">
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-html-starter-project.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Html
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-laravel-starter-project.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    Laravel
                                                </a>
                                                <a
                                                    target="_blank"
                                                    href="https://acorn-dotnet-starter-project.coloredstrategies.com/"
                                                    className="btn btn-primary btn-sm sw-10 sw-lg-12 d-block mx-auto my-1"
                                                >
                                                    .Net5
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Niches Modal End */}

            {/* Theme Settings & Niches Buttons Start */}
            <div className="settings-buttons-container">
                {/*<button type="button" className="btn settings-button btn-primary p-0" data-bs-toggle="modal" data-bs-target="#settings" id="settingsButton">
                    <span className="d-inline-block no-delay" data-bs-delay="0" data-bs-offset="0,3" data-bs-toggle="tooltip" data-bs-placement="left" title="Settings">
                        <i data-acorn-icon="paint-roller" className="position-relative"></i>
                    </span>
                </button>
                <button type="button" className="btn settings-button btn-primary p-0" data-bs-toggle="modal" data-bs-target="#niches" id="nichesButton">
                    <span className="d-inline-block no-delay" data-bs-delay="0" data-bs-offset="0,3" data-bs-toggle="tooltip" data-bs-placement="left" title="Niches">
                        <i data-acorn-icon="toy" className="position-relative"></i>
                    </span>
                    </button>*/}
            </div>
            {/* Theme Settings & Niches Buttons End */}

            {/* Search Modal Start */}
            <div className="modal fade modal-under-nav modal-search modal-close-out" id="searchPagesModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-0">
                            <button type="button" className="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body ps-5 pe-5 pb-0 border-0">
                            <input id="searchPagesInput" className="form-control form-control-xl borderless ps-0 pe-0 mb-1 auto-complete" type="text" autocomplete="off" />
                        </div>
                        <div className="modal-footer border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
                            <span className="text-alternate d-inline-block m-0 me-3">
                                <i data-acorn-icon="arrow-bottom" data-acorn-size="15" className="text-alternate align-middle me-1"></i>
                                <span className="align-middle text-medium">Navigate</span>
                            </span>
                            <span className="text-alternate d-inline-block m-0 me-3">
                                <i data-acorn-icon="arrow-bottom-left" data-acorn-size="15" className="text-alternate align-middle me-1"></i>
                                <span className="align-middle text-medium">Select</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* Search Modal End */}
        </>
    )
}

export default Footer;


