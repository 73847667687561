import React, { useState, useHook, useEffect, useRef } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import Footer from "../Footer";
import Loader from "../Loader";
import "../../index.css";

import { get, post, put, deleteM } from "../../utility/Adapter";
import { createError, removeErrors } from "../../utility/Validation";
import dimage from "../../images/document.jpg";
import Cookies from "js-cookie";
import axios from "axios";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Verify = () => {
  const [loading, setLoading] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [step, setStep] = useState("4");

  useEffect(() => {
    _export_cv();
    _getDocumentId();
    _loadProfile();
  }, []);

  const _getDocumentId = () => {
    get("candidates/document_id").then(async function (response) {
      setDocumentId(response.data.data);
    });
  };

  const _export_cv = () => {
    get('candidates/export_cv').then(async function (response) {
        setCandidateId(response.data.data);
    });
}

  const _loadProfile = () => {
    setLoading(true);
    get('candidates/profile').then(async function (response) {
        setStep(response.data.data.candidate_step);
        setLoading(false);
    }).catch(function (error) {
        console.log(error);
    });
}

  const accept = () => {
    confirmAlert({
      title: "Confirm to approve",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptDoc(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const acceptDoc = () => {
    var raw = JSON.stringify({
      document_id: documentId,
    });

    console.log(raw);

    post("candidates/translator/document_approve", raw)
      .then(async function (response) {
        alert("Successfully approved!");
        setStep("5");
      })
      .catch(function (error) {});
  };

  const changeStatus = () => {
    window.$('#changeStatusModal').modal('toggle');
}

  const changeDocStatus = () => {
    var change_note = document.getElementById('change_note').value;

        var raw = JSON.stringify({
            "document_id": documentId,
            "note": "Rejected By Candidate: " + change_note
        });

        post('candidates/translator/change_status', raw).then(async function (response) {
            window.$('#changeStatusModal').modal('toggle');
            alert('Successfully submitted!');
            window.location.href = "/documents";
        }).catch(function (error) {
        });
  }

  return (
    <>
      <Header />
      <Loader loading={loading} />

    
      <div class="modal fade" id="changeStatusModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabelDefault" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabelDefault">Reject Document</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label class="mb-3 top-label">
                        <textarea type="text" id="change_note" class="form-control required" data-error="Change Note" />
                        <span style={{ color: 'rgb(94, 92, 92)' }}>Reason</span>
                    </label>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" onClick={() => changeDocStatus()}>Reject</button>
                </div>
            </div>
        </div>
    </div>


      <main>
        <div className="container-fluid">
          <div className="row">
            {/* Menu Start */}
            <SideBar step={step} />
            {/*  Menu End */}

            <div className="col">
              {/* Title and Top Buttons Start */}
              <div class="page-title-container">
                <div class="row">
                  <div class="col-12 col-md-7">
                    <h1 class="mb-0 pb-0 display-4" id="title">
                      {step == 4 ? "Verify Documents" : "Documents"}
                    </h1>
                  </div>

                  {step == "4" && (
                    <div class="col-12 col-md-5 d-flex align-items-start justify-content-end">
                      <button
                        type="button"
                        class="btn btn-outline-success btn-icon btn-icon-start w-100 w-md-auto"
                        onClick={() => accept()}
                      >
                        <span>Approve</span>
                      </button>

                      <div class="ms-1">
                        <button
                          type="button"
                          class="btn btn-outline-danger btn-icon btn-icon-start w-100 w-md-auto"
                          onClick={() => changeStatus()}
                        >
                          <span>Reject</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 mt-5">
                  {step == "5" && (
                    <div class="alert alert-success" role="alert">
                      <h4 class="alert-heading">Well done!</h4>
                      <p>Your document translation process completed..</p>
                      <hr />
                      <p class="mb-0">

                        <a
                        className="me-2"
                          target={"_blank"}
                          href={
                            process.env.REACT_APP_BASE_URL +
                            "candidates/download/" +
                            candidateId
                          }
                        >
                          Click Here to Download Resume
                        </a> | 
                        <a
                          className="ml-2 ps-2"
                          target={"_blank"}
                          href={
                            process.env.REACT_APP_BASE_URL +
                            "candidates/download/trans_" +
                            candidateId
                          }
                        >
                           Click Here to Download Translated Document
                        </a>

                      </p>
                    </div>
                  )}
                  <h4>Translated Document</h4>
                  <hr />
                  <embed
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "candidates/download/trans_" +
                      documentId
                    }
                    type="application/pdf"
                    width={"100%"}
                    height={800}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Verify;
