import React, { useState, useHook, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

const Logout = () => {
    
    useEffect(() => {
        Cookies.remove('hcUser');
        Cookies.remove('hcUserData');

        window.location = 'https://hireandcare.de/login';

    }, []);
    
    return (
        <>
        
        </>
    )
}

export default Logout;