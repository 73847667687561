import React, { useState, useHook, useEffect, useRef } from 'react';
import logo from '../images/logo.svg';
import Cookies from 'js-cookie';
import { get, post, put, deleteM } from "../utility/Adapter";
import { createError, removeErrors } from "../utility/Validation";
import avatar from '../images/avatar.jpg';

const Header = () => {

    const [u, setU] = useState({
        name: null
    });

    useEffect(function () {
        getData();
    }, []);

    const getData = () => {
        try {
            

            
                get('candidates/profile/name')
                    .then(async function (response) {
                        setU(response.data.data);
                        Cookies.set('hcUserData', JSON.stringify(response.data.data), { expires: 7 });
                    }).catch(function (error) {
                        window.location = '/login';
                    });
            
        } catch (e) {
            // error reading value
        }
    }


    return (
        <>
            <div id="nav" className="nav-container d-flex">
                <div className="nav-content d-flex">
                    {/* Logo Start */}
                    <div className="logo position-relative">
                        <a href="/">
                            {/* Logo can be added directly */}
                            <div class="img" style={{backgroundImage: `url(${logo})`, width: 300,minHeight: 52, marginTop:'-3px'}}></div>

                            {/* Or added via css to provide different ones for different color themes */}
                            {/*<div className="img">Auto AID</div>*/}
                        </a>
                    </div>
                    {/* Logo End */}

                    <div class="menu-container flex-grow-1">
            
          <ul id="menu" class="menu show">
              <li>
                <a href="/profile" data-href="Dashboards.html">
                <i data-acorn-icon="user" class="icon" data-acorn-size="18"></i>
                  <span class="label">Profile</span>
                </a>
              </li>
              <li>
                <a href="/enquiries" data-href="enquiries">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-screen icon"><path d="M10 15V16V18M8 18H12"></path><path d="M14.5 2C15.9045 2 16.6067 2 17.1111 2.33706C17.3295 2.48298 17.517 2.67048 17.6629 2.88886C18 3.39331 18 4.09554 18 5.5L18 11.5C18 12.9045 18 13.6067 17.6629 14.1111C17.517 14.3295 17.3295 14.517 17.1111 14.6629C16.6067 15 15.9045 15 14.5 15L5.5 15C4.09554 15 3.39331 15 2.88886 14.6629C2.67048 14.517 2.48298 14.3295 2.33706 14.1111C2 13.6067 2 12.9045 2 11.5L2 5.5C2 4.09554 2 3.39331 2.33706 2.88886C2.48298 2.67048 2.67048 2.48298 2.88886 2.33706C3.39331 2 4.09554 2 5.5 2L14.5 2Z"></path><path d="M9 7 7 10M13.2412 7 11.2412 10"></path></svg>
                  <span class="label">Enquiries</span>
                </a>
              </li>
              
              
              
            </ul></div>

                    {/* User Menu Start */}
                    <div className="user-container d-flex">
                        <a href="#" className="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="profile" alt="profile" src={avatar} />
                            <div className="name text-uppercase">{u.candidate_first_name} {u.candidate_last_name}</div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end user-menu wide">
                            <div className="row mb-3 ms-0 me-0">
                                <div className="col-12 ps-1 mb-2">
                                    <div className="text-extra-small text-primary">ACCOUNT</div>
                                </div>
                                <div className="col-6 ps-1 pe-1">
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="/profile">Profile</a>
                                        </li>
                                        {/*<li>
                                            <a href="#">Preferences</a>
                                        </li>
                                        <li>
                                            <a href="#">Calendar</a>
    </li>*/}
                                    </ul>
                                </div>
                                <div className="col-6 pe-1 ps-1">
                                    <ul className="list-unstyled">
                                        {/*<li>
                                            <a href="#">Security</a>
                                        </li>
                                        <li>
                                            <a href="#">Billing</a>
</li>*/}
                                    </ul>
                                </div>
                            </div>
                            {/*<div className="row mb-1 ms-0 me-0">
                                <div className="col-12 p-1 mb-2 pt-2">
                                    <div className="text-extra-small text-primary">APPLICATION</div>
                                </div>
                                <div className="col-6 ps-1 pe-1">
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="#">Themes</a>
                                        </li>
                                        <li>
                                            <a href="#">Language</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 pe-1 ps-1">
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="#">Devices</a>
                                        </li>
                                        <li>
                                            <a href="#">Storage</a>
                                        </li>
                                    </ul>
                                </div>
</div>*/}
                            <div className="row mb-1 ms-0 me-0">
                                <div className="col-12 p-1 mb-3 pt-3">
                                    <div className="separator-light"></div>
                                </div>
                                <div className="col-6 pe-1 ps-1">
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="/logout">
                                                <i data-acorn-icon="logout" className="me-2" data-acorn-size="17"></i>
                                                <span className="align-middle">Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* User Menu End */}

                    {/* Icons Menu Start */}
                    <ul className="list-unstyled list-inline text-center menu-icons">
                        {/*<li className="list-inline-item">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#searchPagesModal">
                                <i data-acorn-icon="search" data-acorn-size="18"></i>
                            </a>
</li>
                        <li className="list-inline-item">
                            <a href="#" id="pinButton" className="pin-button">
                                <i data-acorn-icon="lock-on" className="unpin" data-acorn-size="18"></i>
                                <i data-acorn-icon="lock-off" className="pin" data-acorn-size="18"></i>
                            </a>
                        </li>*/}
                        <li className="list-inline-item">
                            <a href="#" id="colorButton">
                                <i data-acorn-icon="light-on" className="light" data-acorn-size="18"></i>
                                <i data-acorn-icon="light-off" className="dark" data-acorn-size="18"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            {/*<a href="#" data-bs-toggle="dropdown" data-bs-target="#notifications" aria-haspopup="true" aria-expanded="false" className="notification-button">
                                <div className="position-relative d-inline-flex">
                                    <i data-acorn-icon="bell" data-acorn-size="18"></i>
                                    <span className="position-absolute notification-dot rounded-xl"></span>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end wide notification-dropdown scroll-out" id="notifications">
                                <div className="scroll">
                                    <ul className="list-unstyled border-last-none">
                                        <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                            <img src="img/profile/profile-1.webp" className="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                            <div className="align-self-center">
                                                <a href="#">Joisse Kaycee just sent a new comment!</a>
                                            </div>
                                        </li>
                                        <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                            <img src="img/profile/profile-2.webp" className="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                            <div className="align-self-center">
                                                <a href="#">New order received! It is total $147,20.</a>
                                            </div>
                                        </li>
                                        <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                                            <img src="img/profile/profile-3.webp" className="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                            <div className="align-self-center">
                                                <a href="#">3 items just added to wish list by a user!</a>
                                            </div>
                                        </li>
                                        <li className="pb-3 pb-3 border-bottom border-separator-light d-flex">
                                            <img src="img/profile/profile-6.webp" className="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                                            <div className="align-self-center">
                                                <a href="#">Kirby Peters just sent a new message!</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
</div>*/}
                        </li>
                    </ul>
                    {/* Icons Menu End */}

                    {/* Menu Start */}
                    <div className="menu-container flex-grow-1">
                        <ul id="menu" className="menu">
                            {/*<li>
                                <a href="Blog.html">
                                    <i data-acorn-icon="file-text" className="icon" data-acorn-size="18"></i>
                                    <span className="label">Blog</span>
                                </a>
                            </li>
                            <li>
                                <a href="Upgrade.html">
                                    <i data-acorn-icon="trend-up" className="icon" data-acorn-size="18"></i>
                                    <span className="label">Upgrade</span>
                                </a>
                            </li>
                            <li>
                                <a href="Community.html">
                                    <i data-acorn-icon="messages" className="icon" data-acorn-size="18"></i>
                                    <span className="label">Community</span>
                                </a>
    </li>*/}
                        </ul>
                    </div>
                    {/* Menu End */}

                    {/* Mobile Buttons Start */}
                    <div className="mobile-buttons-container">
                        {/* Menu Button Start */}
                        <a href="#" id="mobileMenuButton" className="menu-button">
                            <i data-acorn-icon="menu"></i>
                        </a>
                        {/* Menu Button End */}
                    </div>
                    {/* Mobile Buttons End */}
                </div>
                <div className="nav-shadow"></div>
            </div>
        </>
    )
}

export default Header;